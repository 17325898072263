.footer {
  padding-top: 72px;
  padding-bottom: 72px;
  background-color: #1d2127;
}
.footer .footer-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
}
.footer_divider {
  width: 1px;
  height: 8px;
  opacity: 1;
  background: #5f5f6c;
  margin: 0 8px 0 7px;
  display: inline-block;
}
.footer-left__logo {
  width: 85px;
  height: 23px;
  background-size: cover;
  background-image: url("../assets/image/footer/TapTap.e4230823.svg");
}
.footer-left__links {
  font-size: 12px;
  line-height: 18px;
  opacity: 0.75;
}
.footer-left__links a,
.footer-left__links .doc-link {
  color: #fff;
}
.footer-left__links-detail {
  cursor: pointer;
  display: inline-block;
}
.footer-left__company {
  opacity: 0.45;
  color: #fff;
  font-size: 10px;
  line-height: 14px;
}
.footer-left__company div {
  margin-top: 14px;
}
.footer-left__contact {
  font-size: 12px;
  opacity: 0.75;
  margin-top: 4px;
  color: #fff;
}
.footer-left__contact a {
  color: #fff;
}
.footer-right {
  font-size: 10px;
  line-height: 14px;
}
.footer-right a {
  color: rgba(255, 255, 255, 0.45);
}
.footer-right img {
  height: 12px;
}
.footer-right > div {
  display: flex;
  cursor: pointer;
  margin-top: 14px;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .footer {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .footer .common-spacing {
    margin-top: 22px;
  }
  .footer-left {
    width: 100%;
  }
  .footer-right > div {
    justify-content: flex-start;
  }
}
@media (min-width: 768px) {
  .footer .common-spacing {
    margin-top: 30px;
  }
}
.lang-en .footer-right {
  display: none;
}

.header {
  top: 0;
  width: 100%;
  padding: 16px 24px;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.header_right_logo {
  height: 17px;
  width: 71px;
  margin-left: 7px;
  margin-bottom: 4px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../assets/image/topNav/adCenter.83b2d334.svg');
}
.header_left {
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 32px;
}
.header_left_logo {
  height: 24px;
  width: 80px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../assets/image/topNav/taptap.e72f1d88.svg');
}
.header_left_divider {
  height: 8px;
  opacity: 0.5;
  margin: 0 7px;
}
.header_right {
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
}
.header_right_divider {
  height: 8px;
  opacity: 0.5;
  margin: 0 12px;
}
@media (max-width: 767px) {
  .header {
    padding: 20px 12px 0;
  }
}
@media (max-width: 1279px) {
  .header_left {
    font-size: 16px;
    line-height: 16px;
  }
  .header_left_logo {
    height: 16px;
    width: 53px;
  }
}
@media (min-width: 1280px) {
  .header_left_divider {
    height: 12px;
    margin: 0 10px;
  }
}

* {
  margin: 0;
  padding: 0;
  border-width: 0;
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
body {
  color: #1D2127;
  position: relative;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', SimSun, sans-serif;
}
button {
  border: 0;
}
button:focus,
button:active,
button:hover {
  outline: 0;
  text-decoration: none;
}
a {
  text-decoration: none;
}
.cursor_pointer {
  cursor: pointer;
}
.common_title {
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
}
@media (max-width: 1279px) {
  .common_title {
    font-size: 28px;
    line-height: 36px;
  }
}
.flex_center {
  display: flex;
  align-items: center;
}
.link {
  color: #00D9C5;
}
.align_center {
  text-align: center;
}
.white_text {
  color: #fff;
}
.font_caption {
  font-size: 12px;
}
.font_content {
  font-size: 14px;
  line-height: 22px;
}
.font_subtitle {
  font-size: 16px;
  line-height: 24px;
}
.font_title {
  font-size: 18px;
}
.font_basic {
  font-size: 24px;
  line-height: 32px;
}
.font_600 {
  font-weight: 600;
}
.font_500 {
  font-weight: 500;
}
.font_bold {
  font-weight: bold;
}
.android .font_600 {
  font-weight: bold;
}
.android .font_500 {
  font-weight: bold;
}
.ad_divider {
  width: 1px;
  height: 12px;
  display: inline-block;
  background-color: #fff;
}
.common_width {
  width: 100%;
  margin: 0 auto;
  max-width: 1184px;
}
.lang-en .en_display_none {
  display: none;
}
@media (max-width: 1279px) {
  .common_padding {
    padding: 48px 0;
  }
  .common_width {
    max-width: 720px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 767px) {
  .common_width {
    max-width: auto;
    padding: 0 12px;
  }
}
@media (min-width: 1280px) {
  .common_padding {
    padding: 104px 0;
  }
}
@font-face {
  font-family: 'DINW05-Bold';
  src: url('../assets/fonts/DINW05-Bold/DINW05-Bold.6bfac179.woff2') format('woff2'), url('../assets/fonts/DINW05-Bold/DINW05-Bold.b9503db2.woff') format('woff');
}
.din_font {
  font-family: 'DINW05-Bold', sans-serif;
}

.game_banner {
  height: 360px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
}
.game_banner_content {
  width: 100%;
  padding-bottom: 80px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.game_banner_left {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.game_banner_avatar {
  width: 128px;
  height: 128px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
}
.game_banner_game_desc {
  margin-left: 16px;
}
.game_banner_game_desc_title {
  font-size: 48px;
  line-height: 56px;
}
.game_banner_game_desc_content {
  font-size: 18px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 8px;
}
.game_banner_game_data {
  font-size: 18px;
  line-height: 26px;
}
.game_banner_game_data_num {
  font-size: 88px;
  line-height: 72px;
  margin: 0 8px;
  font-weight: bold;
}
.game_banner_game_data_common_margin {
  margin-left: 24px;
}
.game_content {
  padding: 48px 48px 104px;
  background-color: #EFF1F2;
}
.game_content_title {
  font-size: 14px;
  color: #646566;
  line-height: 22px;
  margin-bottom: 48px;
}
.game_content_title_link {
  color: #00D9C5;
}
.game_content_list_detail {
  width: 100%;
  padding: 38px;
  margin-top: 24px;
  border-radius: 8px;
  background-color: #fff;
  transition: all 0.3s;
}
@media (min-width: 768px) {
  .game_content_list_detail:hover {
    filter: drop-shadow(16px 20px 32px rgba(0, 0, 0, 0.16));
  }
}
.game_content_list_detail:first-child {
  margin-top: 0;
}
.game_content_list_detail_title {
  font-size: 28px;
  line-height: 36px;
}
.game_content_list_detail_content {
  font-size: 18px;
  margin-top: 28px;
  line-height: 26px;
}
.cat_planet .game_banner {
  background-image: url('../assets/image/gameDetail/1920/case1@2x.8da7084e.jpg');
}
.cat_planet .game_banner_avatar {
  background-image: url('../assets/image/gameDetail/avatar/case1@2x.e3858962.jpg');
}
.moonlight_blade .game_banner {
  background-image: url('../assets/image/gameDetail/1920/case2@2x.b3a6f60d.jpg');
}
.moonlight_blade .game_banner_game_data_num {
  margin: 0 8px 0 0;
}
.moonlight_blade .game_banner_avatar {
  background-image: url('../assets/image/gameDetail/avatar/case2@2x.df41b24c.jpg');
}
.manasis_refrain .game_banner {
  background-image: url('../assets/image/gameDetail/1920/case3@2x.1a6a4ba9.jpg');
}
.manasis_refrain .game_banner_avatar {
  background-image: url('../assets/image/gameDetail/avatar/case3@2x.81ee94bb.jpg');
}
@media (max-width: 1279px) {
  .game_banner {
    height: 290px;
  }
  .game_banner_content {
    padding-bottom: 105px;
  }
  .game_banner_avatar {
    width: 80px;
    height: 80px;
  }
  .game_banner_game_desc {
    margin-left: 8px;
  }
  .game_banner_game_desc_title {
    font-size: 28px;
    line-height: 36px;
  }
  .game_banner_game_desc_content {
    margin-top: 4px;
    font-size: 16px;
    line-height: 24px;
  }
  .game_banner_game_data {
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    line-height: 24px;
  }
  .game_banner_game_data_num {
    font-size: 48px;
    line-height: 40px;
  }
  .game_content {
    padding: 32px 24px 48px;
  }
  .game_content_list_detail {
    margin-top: 8px;
    padding: 24px 24px 32px;
  }
  .game_content_list_detail_title {
    font-size: 24px;
    line-height: 32px;
  }
  .game_content_list_detail_content {
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;
  }
  .game_content_title {
    margin-bottom: 32px;
  }
  .cat_planet .game_banner {
    background-image: url('../assets/image/gameDetail/768/case1@2x.82b61f59.jpg');
  }
  .moonlight_blade .game_banner {
    background-image: url('../assets/image/gameDetail/768/case2@2x.ad2846aa.jpg');
  }
  .manasis_refrain .game_banner {
    background-image: url('../assets/image/gameDetail/768/case3@2x.17519615.jpg');
  }
}
@media (max-width: 767px) {
  .game_banner_content {
    padding: 0 12px 32px 12px;
    align-items: initial;
    flex-direction: column;
    justify-content: flex-end;
  }
  .game_banner_left {
    width: 100%;
    justify-content: initial;
  }
  .game_banner_game_desc {
    margin-left: 8px;
  }
  .game_banner_game_desc_content {
    font-size: 12px;
    line-height: 18px;
  }
  .game_banner_game_data {
    font-size: 12px;
    line-height: 18px;
    margin-top: 50px;
  }
  .game_content {
    padding: 32px 0 48px;
  }
  .game_content_title {
    margin-bottom: 30px;
  }
  .game_content_list_detail {
    padding: 12px 12px 60px;
  }
  .cat_planet .game_banner {
    background-image: url('../assets/image/gameDetail/320/case1@2x.b081a63e.jpg');
  }
  .cat_planet .game_banner_game_data_num:first-child {
    margin: 0 8px 0 0;
  }
  .moonlight_blade .game_banner {
    background-image: url('../assets/image/gameDetail/320/case2@2x.e110938f.jpg');
  }
  .manasis_refrain .game_banner {
    background-image: url('../assets/image/gameDetail/320/case3@2x.43519ea5.jpg');
  }
}
@media (min-width: 768px) and (max-width: 1280px) {
  .game_banner_game_desc_content {
    max-width: 300px;
  }
}
@media (min-width: 1279px) {
  .game_banner_content {
    padding: 0 0 80px;
  }
}
@media (min-width: 2560px) {
  .game_banner {
    height: 480px;
  }
  .cat_planet .game_banner {
    background-image: url('../assets/image/gameDetail/2560/case1@2x.75cca229.jpg');
  }
  .moonlight_blade .game_banner {
    background-image: url('../assets/image/gameDetail/2560/case2@2x.0c0631b2.jpg');
  }
  .manasis_refrain .game_banner {
    background-image: url('../assets/image/gameDetail/2560/case3@2x.aa14bf01.jpg');
  }
}
.lang-en .game_banner_game_desc {
  flex: 1;
}
.lang-en .game_banner_game_desc_title {
  font-size: 42px;
}
.lang-en .game_banner_game_data_num {
  font-size: 52px;
  line-height: 60px;
}
.lang-en .game_banner_left {
  flex: 1;
}
.lang-en .game_banner_center {
  width: 100px;
}
@media (max-width: 1279px) {
  .lang-en .game_banner_content {
    padding: 0 12px 32px 12px;
    align-items: initial;
    flex-direction: column;
    justify-content: flex-end;
  }
  .lang-en .game_banner_game_desc_title {
    font-size: 22px;
    line-height: 28px;
  }
  .lang-en .game_banner_game_desc_content {
    font-size: 12px;
    line-height: 18px;
    max-width: 100%;
  }
  .lang-en .game_banner_game_data {
    font-size: 14px;
    margin-top: 25px;
  }
  .lang-en .game_banner_game_data_num {
    font-size: 48px;
    line-height: 60px;
  }
  .lang-en .game_banner_left {
    width: 100%;
    align-items: flex-start;
    justify-content: initial;
  }
}
@media (max-width: 767px) {
  .lang-en .game_banner_game_data {
    margin-top: 20px;
  }
  .lang-en .game_banner_game_data_num {
    font-size: 34px;
    line-height: 40px;
  }
}

.ad_advantage {
  background-color: #FAFAFA;
}
.ad_advantage_content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 72px;
  justify-content: space-between;
}
.ad_advantage_content_card {
  width: 50%;
  padding: 0 12px;
  margin-bottom: 24px;
}
.ad_advantage_content_card:nth-child(2n+1) {
  padding-left: 0;
}
.ad_advantage_content_card:nth-child(2n) {
  padding-right: 0;
}
.ad_advantage_content_card > div {
  height: 100%;
  border-radius: 8px;
  transition: all 0.3s;
  padding: 32px 16px 24px;
  background-color: #fff;
}
@media (min-width: 768px) {
  .ad_advantage_content_card > div:hover {
    filter: drop-shadow(16px 20px 32px rgba(0, 0, 0, 0.16));
  }
}
.ad_advantage_content_card_title {
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
}
.ad_advantage_content_card_sub_title {
  font-size: 14px;
  margin: 32px 0 8px;
  line-height: 24px;
}
.ad_advantage_content_card_content {
  font-size: 14px;
  line-height: 22px;
  color: #868C92;
}
.ad_advantage_content_card_icon {
  width: 32px;
  height: 32px;
  margin-right: 4px;
  display: inline-block;
  background-size: contain;
}
.ad_advantage_content_card_icon_accurate {
  background-image: url("../assets/image/adAdvantage/accurate@2x.e65d57aa.png");
}
.ad_advantage_content_card_icon_put {
  background-image: url("../assets/image/adAdvantage/put@2x.7d547392.png");
}
.ad_advantage_content_card_icon_intelligent {
  background-image: url("../assets/image/adAdvantage/intelligent@2x.759db34a.png");
}
.ad_advantage_content_card_icon_additional {
  background-image: url("../assets/image/adAdvantage/additional@2x.fc425674.png");
}
@media (max-width: 1279px) {
  .ad_advantage_content {
    margin-top: 48px;
  }
  .ad_advantage_content_card {
    padding: 0 4px;
    margin-bottom: 8px;
  }
  .ad_advantage_content_card:nth-child(3) {
    margin-bottom: 8px;
  }
  .ad_advantage_content_card_icon {
    width: 28px;
    height: 28px;
  }
  .ad_advantage_content_card_title {
    font-size: 20px;
    line-height: 28px;
  }
  .ad_advantage_content_card_sub_title {
    font-size: 12px;
    line-height: 18px;
    margin: 28px 0 8px;
  }
  .ad_advantage_content_card_content {
    font-size: 12px;
    line-height: 18px;
  }
  .ad_advantage_content_card > div {
    padding: 24px 12px;
  }
}
@media (max-width: 767px) {
  .ad_advantage_content {
    margin-top: 48px;
  }
  .ad_advantage_content_card {
    width: 100%;
    padding: 0;
  }
  .ad_advantage_content_card_sub_title {
    margin: 20px 0 8px;
  }
  .ad_advantage_content_card > div {
    padding: 16px 12px 34px;
  }
}

.ad_style {
  background: #00D9C5;
}
.ad_style.common_padding {
  padding-bottom: 0;
}
.ad_style_in_view .ad_style_slick_content_detail_img {
  opacity: 1;
  bottom: 0;
}
.ad_style_slick {
  position: relative;
  margin-top: 83px;
  display: flex;
  align-items: center;
}
.ad_style_slick_content {
  max-width: 1120px;
  padding: 0 69px;
}
.ad_style_slick_content_detail {
  display: flex;
}
.ad_style_slick_content_detail_text {
  align-self: center;
}
.ad_style_slick_content_detail_text_title {
  font-size: 24px;
  line-height: 32px;
}
.ad_style_slick_content_detail_text_content {
  margin-right: 24px;
  font-size: 16px;
  line-height: 24px;
  margin-top: 24px;
}
.ad_style_slick_content_detail_divider {
  width: 0;
  height: 24px;
  padding: 0px;
  margin: 0 12px;
  display: inline-block;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
}
.ad_style_slick_content_detail_img {
  min-width: 497px;
  height: 557px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: relative;
  opacity: 0.2;
  bottom: -400px;
  transition: all 1s;
}
.ad_style_slick_content_detail_img_profile {
  background-image: url("../assets/image/adStyle/profileInfo@2x.d9978fa9.png");
}
.ad_style_slick_content_detail_img_game {
  background-image: url("../assets/image/adStyle/findGame@2x.b4ccdbad.png");
}
.ad_style_slick_content_detail_custom_dot {
  line-height: 0;
  margin-top: 40px;
}
.ad_style_slick_content_detail_custom_dot span {
  display: inline-block;
  width: 6px;
  height: 6px;
  opacity: 0.5;
  border-radius: 6px;
  background-color: #fff;
}
span.ad_style_slick_content_detail_custom_dot_active {
  opacity: 1;
  width: 12px;
}
.ad_style_slick_arrow {
  width: 0;
  height: 0;
  padding: 16px;
  background-size: contain;
  background-position: left center;
}
.ad_style_slick_prev {
  background-image: url("../assets/image/adStyle/pre@2x.7fa7e68a.png");
}
.ad_style_slick_next {
  right: 0;
  position: absolute;
  background-image: url("../assets/image/adStyle/next@2x.732c2942.png");
}
@media (max-width: 1279px) {
  .ad_style_slick {
    margin-top: 74px;
  }
  .ad_style_slick_content {
    padding: 0;
    width: 704px;
  }
  .ad_style_slick_content_detail_text_title {
    font-size: 20px;
    line-height: 28px;
  }
  .ad_style_slick_content_detail_text_content {
    margin-right: 41px;
    font-size: 14px;
    line-height: 22px;
    margin-top: 16px;
    min-height: 66px;
  }
  .ad_style_slick_content_detail_img {
    min-width: 323px;
    height: 362px;
  }
  .ad_style_slick_prev,
  .ad_style_slick_next {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .ad_style_slick {
    margin-top: 22px;
  }
  .ad_style_slick_content {
    width: 100%;
    padding: 0;
    text-align: center;
  }
  .ad_style_slick_content_detail {
    flex-wrap: wrap;
  }
  .ad_style_slick_content_detail_text {
    margin: 0 auto;
  }
  .ad_style_slick_content_detail_text_title {
    font-size: 16px;
    line-height: 24px;
    justify-content: center;
  }
  .ad_style_slick_content_detail_text_content {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    padding: 0 24px;
    margin-right: 0;
    min-height: 54px;
  }
  .ad_style_slick_content_detail_divider {
    height: 8px;
    margin: 0 8px;
  }
  .ad_style_slick_content_detail_img {
    height: 289px;
    min-width: 250px;
    margin: 16px auto 0;
  }
  .ad_style_slick_content_detail_custom_dot {
    margin-top: 16px;
    text-align: center;
  }
}
.lang-en .ad_style_slick_content {
  padding: 0 40px;
}
.lang-en .ad_style_slick_content_detail_text_title {
  font-size: 18px;
  line-height: 24px;
}
.lang-en .ad_style_slick_content_detail_text_content {
  font-size: 14px;
  line-height: 20px;
}
.lang-en .ad_style_slick_content_detail_img_profile {
  background-image: url("../assets/image/adStyle/en/profileInfo@2x.acafcb12.png");
}
@media (max-width: 1279px) {
  .lang-en .ad_style_slick {
    width: 93.75%;
    max-width: 850px;
  }
  .lang-en .ad_style_slick_content {
    padding: 0;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .lang-en .ad_style_slick_content_detail_text_title {
    font-size: 14px;
    line-height: 22px;
  }
  .lang-en .ad_style_slick_content_detail_text_content {
    padding: 0;
    font-size: 12px;
    line-height: 18px;
  }
  .lang-en .ad_style_slick_content_detail_divider {
    margin: 0 12px;
  }
}

.banner {
  height: 720px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #00d9c5;
}
.banner_title {
  font-size: 72px;
  line-height: 80px;
}
.banner_system_data {
  margin-top: 52px;
  display: flex;
  align-items: center;
}
.banner_system_data_title {
  font-size: 16px;
  line-height: 24px;
}
.banner_system_data_divider {
  width: 0;
  height: 24px;
  margin: 0 24px;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
}
.banner_system_data_detail {
  margin-top: 4px;
  font-size: 40px;
  line-height: 40px;
}
.banner_link {
  margin-top: 70px;
}
.banner_link_button {
  cursor: pointer;
  font-size: 18px;
  line-height: 26px;
  padding: 13px 54px;
  border-radius: 54px;
  border: 2px solid #fff;
  background-color: #fff;
}
.banner_link_button_ad {
  color: #1d2127;
}
.banner_link_button_contact_us {
  margin-left: 24px;
  background-color: transparent;
}
@media (max-width: 1279px) {
  .banner {
    height: 580px;
  }
  .banner_section {
    width: 720px;
    margin: 0 auto;
  }
  .banner_title {
    font-size: 36px;
    line-height: 44px;
  }
  .banner_system_data {
    margin-top: 40px;
  }
  .banner_system_data_title {
    font-size: 12px;
    line-height: 18px;
  }
  .banner_system_data_detail {
    font-size: 20px;
    line-height: 20px;
  }
  .banner_link {
    margin-top: 56px;
  }
  .banner_link_button {
    padding: 4px 38px;
    font-size: 16px;
    line-height: 24px;
  }
  .banner_link_button_contact_us {
    margin-left: 8px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .banner {
    background-image: url("../assets/image/banner/bg768@2x.f37b3fcc.png");
  }
  .banner_link {
    margin-top: 56px;
  }
}
@media (min-width: 1280px) {
  .banner_section {
    padding-left: calc(((100% - 1280px) * 154 / 640) + 48px);
  }
}
@media (min-width: 1280px) and (max-width: 1899px) {
  .banner {
    height: 720px;
    background-image: url("../assets/image/banner/bg1280@2x.6f0d814e.png");
  }
}
@media (min-width: 1900px) and (max-width: 2559px) {
  .banner {
    height: 720px;
    background-size: cover;
    background-image: url("../assets/image/banner/bg2560@2x.6f0d814e.png");
  }
  .banner_section {
    padding-left: calc(((100% - 1280px) * 154 / 640) + 48px);
  }
}
@media (min-width: 2560px) {
  .banner {
    height: 960px;
    background-image: url("../assets/image/banner/bg2560@2x.6f0d814e.png");
  }
}
@media (max-width: 767px) {
  .banner {
    padding-top: 83px;
    background-size: cover;
    align-items: flex-start;
    background-image: url("../assets/image/banner/bg320@3x.4170ef01.png");
  }
  .banner_section {
    padding: 0 0;
    margin: 0 auto;
  }
  .banner_title {
    text-align: center;
  }
  .banner_system_data {
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .banner_system_data_divider {
    margin: 0 16px;
  }
  .banner_link {
    margin-top: 40px;
    text-align: center;
  }
}
.lang-en .banner_title {
  font-size: 58px;
  line-height: 60px;
}
.lang-en .banner_title_content {
  width: 750px;
}
@media (max-width: 1279px) {
  .lang-en .banner_title {
    font-size: 32px;
    line-height: 40px;
  }
  .lang-en .banner_title_content {
    width: 400px;
  }
  .lang-en .banner_link_button {
    font-size: 14px;
    line-height: 22px;
  }
  .lang-en .banner_system_data_divider {
    margin: 0 20px;
  }
}
@media (max-width: 768px) {
  .lang-en .banner_title {
    font-size: 28px;
  }
  .lang-en .banner_title_content {
    width: auto;
    margin: 0 auto;
  }
  .lang-en .banner_system_data_divider {
    margin: 0 18px;
  }
  .lang-en .banner .banner_system_data_title {
    font-size: 10px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .lang-en .banner {
    background-image: url("../assets/image/banner/en/bg768@2x.8c3eeef1.jpg");
    background-position-x: 0px;
  }
}
@media (min-width: 1280px) and (max-width: 2559px) {
  .lang-en .banner {
    background-image: url("../assets/image/banner/en/bg1920@2x.835affb7.jpg");
  }
}
@media (min-width: 2560px) {
  .lang-en .banner {
    background-image: url("../assets/image/banner/en/bg2560@2x.02ec8eda.jpg");
  }
}
@media (max-width: 767px) {
  .lang-en .banner {
    background-image: url("../assets/image/banner/en/bg320@3x.c26940b5.jpg");
  }
}

.cases {
  background-color: #EFF1F2;
}
.cases_content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 72px;
  justify-content: space-between;
}
.cases_content_card {
  overflow: hidden;
}
.cases_content_card_white {
  width: 24px;
}
.cases_content_card_content {
  overflow: hidden;
  border-radius: 16px 16px 8px 8px;
  transition: all 0.3s;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .cases_content_card:hover {
    filter: drop-shadow(16px 20px 32px rgba(0, 0, 0, 0.16));
  }
}
.cases_content_card_img {
  height: 0;
  width: 100%;
  padding: 28.04054054% 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.cases_content_card_img_cat_planet {
  background-image: url("../assets/image/cases/case1@2x.afee48c2.jpg");
}
.cases_content_card_img_manasis_refrain {
  background-image: url("../assets/image/cases/case2@2x.680c2832.jpg");
}
.cases_content_card_img_moonlight_blade {
  background-image: url("../assets/image/cases/case3@2x.0632d2fa.jpg");
}
.cases_content_card_detail {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cases_content_card_detail_divider {
  border-bottom: 0.5px solid #EBEFF0;
  transform: scale(1, 0.5);
}
.cases_content_card_detail_title {
  font-size: 24px;
  line-height: 32px;
}
.cases_content_card_detail_desc {
  color: #868C92;
  font-size: 14px;
  line-height: 22px;
  margin-top: 8px;
  min-height: 44px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.cases_content_card_detail_top,
.cases_content_card_detail_bottom {
  padding: 16px;
}
.cases_content_card_detail_bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 40px;
  vertical-align: bottom;
}
.cases_content_card_detail_data {
  font-size: 14px;
  line-height: 22px;
  margin-right: 40px;
  display: inline-block;
}
.cases_content_card_detail_data:last-child {
  margin-right: 0;
}
.cases_content_card_detail_data_num {
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
}
@media (min-width: 768px) {
  .cases_content_card_content {
    flex: 1;
  }
}
@media (max-width: 1279px) {
  .cases_content {
    margin-top: 48px;
  }
  .cases_content_card_detail_title {
    font-size: 20px;
    line-height: 28px;
  }
  .cases_content_card_detail_desc {
    font-size: 12px;
    line-height: 18px;
    min-height: 36px;
  }
  .cases_content_card_detail_data {
    font-size: 12px;
    line-height: 18px;
    margin-right: 24px;
  }
  .cases_content_card_detail_data_num {
    font-size: 20px;
    line-height: 20px;
    display: inline-block;
    margin: 0 2px;
  }
  .cases_content_card_detail_top {
    padding: 12px;
  }
  .cases_content_card_detail_bottom {
    padding: 25px 12px;
  }
  .cases_content_card_white {
    width: 8px;
  }
}
@media (max-width: 767px) {
  .cases_content_card_content {
    width: 100%;
    margin: 0 0 8px;
  }
  .cases_content_card_white {
    width: 0;
  }
  .cases_content_card_img {
    padding: 28.08% 0;
  }
}
.lang-en .cases_content_card_detail_data_num {
  font-size: 32px;
}
@media (max-width: 1279px) {
  .lang-en .cases_content_card_detail_data_num {
    font-size: 16px;
  }
}

.contact_us {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  padding: 100px 24px 104px 24px;
}
.contact_us_link {
  margin-top: 16px;
  line-height: 26px;
  font-size: 0;
}
.contact_us_link span,
.contact_us_link img {
  font-size: 18px;
  vertical-align: middle;
}
.contact_us_link span {
  margin-right: 4px;
}
.contact_us_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1186px;
}
.contact_us_note {
  margin-top: 48px;
  line-height: 26px;
  font-size: 0;
}
.contact_us_note span {
  font-size: 18px;
  vertical-align: middle;
  margin-right: 4px;
  color: #b9bec1;
}
.contact_us_split_line {
  width: 100%;
  height: 0.5px;
  background: #d2d7d9;
  margin: 48px 0;
}
.contact_us_official {
  display: flex;
  padding: 24px 60px;
  width: 400px;
  align-items: center;
  justify-content: center;
  margin: 60px 0;
  font-size: 0;
  background: #fff;
  box-shadow: 2px 2px 15px 0px rgba(91, 91, 91, 0.08);
  border-radius: 100px;
  line-height: 22px;
}
.contact_us_official span {
  font-size: 18px;
  margin-right: 8px;
}
.contact_us_official span:first-child {
  font-size: 20px;
  flex-shrink: 0;
  margin-right: 24px;
}
.contact_us_official_copy_btn {
  display: inline-block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-image: url("../assets/image/contactUs/copy.ba799d63.svg");
  background-repeat: no-repeat;
  background-position: center center;
}
.contact_us_title {
  width: 100%;
  margin-bottom: 32px;
  color: #1d2127;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.contact_us_content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(374px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 32px;
  margin: 0 auto;
  width: 100%;
}
.contact_us_content_section {
  display: flex;
  position: relative;
  width: 100%;
  height: 262px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: #fff;
  padding: 32px;
  border-radius: 8px;
  overflow: hidden;
}
.contact_us_content_section:last-child {
  margin-bottom: 0;
}
.contact_us_content_section_title {
  display: inline-block;
  font-size: 20px;
  text-align: left;
  line-height: 28px;
}
.contact_us_content_section_detail {
  display: flex;
  flex-direction: column;
}
.contact_us_content_section_detail > span {
  flex-wrap: wrap;
  align-items: center;
  display: inline-flex;
  margin-bottom: 16px;
}
.contact_us_content_section_detail > span:last-child {
  margin-bottom: 0;
}
.contact_us_content_section_detail_qq {
  width: 240px;
  margin-right: 0 !important;
}
.contact_us_content_section_detail_icon {
  width: 24px;
  height: 24px;
  line-height: 0;
  margin-right: 10px;
  display: inline-block;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #d2d7d9;
  border-radius: 50%;
}
.contact_us_content_section_detail_qq_icon {
  background-image: url("../assets/image/contactUs/qq.2e56e6a8.svg");
}
.contact_us_content_section_detail_mobile_icon {
  background-image: url("../assets/image/contactUs/phone.396aedef.svg");
}
.contact_us_content_section_detail_mail_icon {
  background-image: url("../assets/image/contactUs/mail.963653c1.svg");
}
.contact_us_content_section_badge {
  position: absolute;
  width: 138px;
  height: 142px;
  right: 0;
  top: 0;
  background-image: url("../assets/image/contactUs/badge.e1c3fe7f.svg");
  background-repeat: no-repeat;
  background-position: right top;
}
@media (max-width: 1234px) {
  .contact_us {
    padding: 48px 24px 48px 24px;
  }
  .contact_us_body {
    max-width: 964px;
  }
  .contact_us_content {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    align-items: stretch;
    justify-items: stretch;
  }
  .contact_us_content_section {
    width: 100%;
    height: 236px;
    padding: 24px;
  }
  .contact_us_content_section_title {
    font-size: 18px;
    line-height: 26px;
  }
  .contact_us_content_section_detail {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 1044px) {
  .contact_us {
    padding: 48px 24px 48px 24px;
  }
  .contact_us_body {
    max-width: 632px;
  }
  .contact_us_content {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    align-items: stretch;
    justify-items: stretch;
  }
  .contact_us_content_section {
    width: 100%;
    height: 236px;
    padding: 24px;
  }
  .contact_us_content_section_title {
    font-size: 18px;
    line-height: 26px;
  }
  .contact_us_content_section_detail {
    font-size: 16px;
    line-height: 24px;
  }
  .contact_us_official {
    margin: 48px 0;
  }
}
@media (max-width: 768px) {
  .contact_us {
    padding: 48px 12px 48px 12px;
  }
  .contact_us_body {
    max-width: 100%;
  }
  .contact_us_content {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
  .contact_us_official {
    padding: 0px;
    width: auto;
    gap: 8px;
    margin: 40px 0;
    background: none;
    box-shadow: none;
    border-radius: 0;
  }
  .contact_us_official span {
    font-size: 16px;
  }
  .contact_us_official span:first-child {
    font-size: 18px;
    flex-shrink: 0;
    margin-right: 0;
  }
  .contact_us_official_copy_btn {
    display: none;
  }
  .contact_us_title {
    font-size: 20px;
  }
}
.lang-en .contact_us .contact_us_content_section_title {
  width: 260px;
}
@media (max-width: 1279px) {
  .lang-en .contact_us .contact_us_content {
    padding: 48px 12px;
  }
  .lang-en .contact_us .contact_us_content_section_title {
    width: 156px;
  }
}
